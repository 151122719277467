<template>
  <section class="invoice-add-wrapper">
    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>

                <p class="card-text mb-25">
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-1"
                  />
                  {{ lead.firstname }}  {{ lead.lastname }}
                </p>
                <p class="card-text mb-25">
                  <feather-icon
                    icon="MailIcon"
                    size="16"
                    class="mr-1"
                  /> {{ lead.email }}
                </p>
                <p class="card-text mb-0">
                  <feather-icon
                    icon="PhoneCallIcon"
                    size="16"
                    class="mr-1"
                  />
                  {{ lead.country_code }}{{ lead.phonenumber.replace(/^0+/, '') }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ $t('LEAD_ID') }}
                  <span class="invoice-number">#{{ lead.ID }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ $t('DATE_TIME') }} :
                  </p>
                  <p class="invoice-date">
                    {{ formatDateTime(lead.created_timestamp) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
          <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- status -->
                <b-col
                  cols="4"
                  md="4"
                >
                  <b-form-group
                    :label="$t('STATUS')"
                    label-for="id-status"
                  >

                    <v-select
                      id="id-status"
                      v-model="status"
                      placeholder="Select Status"
                      label="name"
                      :clearable="false"
                      class="flex-grow-1"
                      :options="statusOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="8"
                  md="8"
                >
                  <!-- Comment -->
                  <b-form-group
                    :label="$t('COMMENT')"
                    label-for="id-comment"
                  >
                    <quill-editor
                      id="id-comment"
                      v-model="lead.comment"
                      maxlength="200"
                      :options="editorOption"
                      class="border-bottom-0"
                    />
                    <div
                      id="quill-toolbar"
                      class="d-flex justify-content-end border-top-0"
                    >
                      <!-- Add a bold button -->
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-align" />
                      <button class="ql-link" />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Spacer -->
            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  {{ lead.ID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  {{ $t('BUTTON.RESET') }}
                </b-button>
              </b-col>
            </b-card-body>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody,
  BButton, BFormGroup, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    BForm,
    vSelect,
    quillEditor,
  },
  mixins: [mixinDate],
  data() {
    return {
      lead: {
        ID: '',
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        country_code: '',
        status: '',
        comment: '',
        created_timestamp: '',
      },
      status: '',
      statusOptions: constants.SALES_LEADS_STATUS,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your lead comment',
    }

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      editorOption,
      successMessage,
      showErrorMessage,
    }
  },
  created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.lead.ID = parseInt(router.currentRoute.params.id)
      if (this.lead.ID !== 0) {
        this.fetchSaleLeadByID(this.lead.ID).then(response => {
          if (response) {
            this.lead = this.getSalesLead
            if (this.lead) {
              this.status = this.statusOptions.find(i => i.name === this.lead.status)
            }
          }
        })
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('report', ['getSalesLead']),
  },
  methods: {
    ...mapActions('report', ['updateSalesLead', 'fetchSaleLeadByID']),
    onSubmit() {
      this.lead.status = this.status.name
      if (this.lead.ID) {
        const payLoad = {
          ID: this.lead.ID,
          status: this.lead.status,
          comment: this.lead.comment,
        }
        this.updateSalesLead(payLoad).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.LEAD_UPDATED'))
            this.$router.push({ name: 'sales-lead-list' })
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
}
</style>
